
.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}


.contact-container h2 {
  margin-bottom: 15px;
}

.contact-container p {
  text-align:  justify;
}

.contact-column {
  width: 46%;
  margin-bottom: 30px;
  margin-top: 30px;
}

.contact-left,
.contact-right {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
}




.contact-form {
  width: 100%;
  margin: 0 auto;
}

.contact-form .form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.6);
}

.contact-form textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form .contact-fullname {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form .file-input {
  margin-bottom: 20px;
}

.contact-form .form-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form button {
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4f89c7;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 50%;
  text-transform: none;
}

.contact-form button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 900px) {
  .contact-container {
    flex-direction: column;
    width: 75%;
  }

  .contact-column {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    width: 90%;
  }

  .contact-column {
    width: 100%;
  }

  .contact-form {
    width: 100%;
  }

  .contact-form .form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .contact-form .form-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .contact-form .contact-title {
    width: 25%;
  }

  .contact-form .contact-fullname {
    width: 100%;
  }

  .contact-form .contact-button {
    width: 100%;
  }
}
