.number-button {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    cursor: pointer !important;
    width: 3vw !important;
    height: 3vw !important;
    border: 2px solid rgb(196, 192, 192);
    margin: 1px 3px 1px 1px !important;
    transition: .2s ease;
    border-radius: 5px;
}

.number-button-name {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    text-align: center !important;
    margin: 0 auto !important;
    font-weight: bold !important;
    color: rgb(14, 13, 13);
    transition: all 0.3s;
}


.true:hover{
  background-color: brown;
}

.false:hover{
    background-color: unset !important;
}