.abstract--link--btn{
    background-color: black !important;
    padding: 8px 12px 8px 12px !important;
    border-radius: 5px;
    color: orange !important;
    &:hover{
        color: rgb(255, 98, 98) !important;
    }
}
.css-13tdnto {
    margin: 0px !important;
}
.css-1w5g4yq{
    margin: 0px !important;
}
@media screen and (max-width: 600px) {
.css-13tdnto {
    margin: 0px !important;
}
}
