

html {
    font-size: 20px;
    color: #000000;
}

.signupmain {
  min-height: 100vh;
}

.signupmain .containermain {
  min-height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupmain section {
    width: 100%;
    max-width: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.9);
}

.signupmain form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

.signupmain  a, a:visited {
    color: #fff;
}

.signupmain .checkbox-label {
    display: flex;
  }
  
.signupmain .checkbox {
    margin-right: 8px; 
  }

.signupmain .checkbox-text {
    cursor: pointer;
    text-decoration: underline;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.signupmain .checkbox-text:hover {
    color: blue;
  }

  

.signupmain input {
  font-family: sans-serif;
  font-size: 18px;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.5);
}

.signupmain label {
  margin-top: 1rem;
}


.signup-btn {
  margin-top: 1rem;
  padding: 0.6rem;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  background-color: #b2b2b2;
  color: #fff;
}

.signup-btn.active {
  background-color: #1976d2 !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5) !important;
}

.signup-btn.active:hover {
  background-color: #1565c0 !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5) !important;
}


.signupmain .google-btn {
    background-color: #f8f8f8;
    color: black;
    border: 1px solid #adacb5;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: none;
    outline: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .signupmain .google-btn:hover {
    background-color: #b80000;
    color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }
  

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #252424;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: rgb(50, 138, 50) !important;
    margin-left: 0.25rem;
}

.invalid {
    color: red !important;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}