.speecies_box{
    display: list-item;
    background-color: #61acc5 !important;
    border: 1px solid #30435f !important;
    border-radius: 5px;
    margin-left: -32px;
    padding: 5px;
    list-style-type: none;
}
.speecies_box p{
    margin-left: 5px;
    font-size: 1rem !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    color: #021037 !important;
}
.speecies{
    display: contents;
    justify-content: center;
}
.speecies div{
    display: -webkit-inline-box !important;
}


.speecies_input{
    max-width: 47px !important;
    margin-left: 3px;
    margin-right: 0px;
}

.tree--item {
    background-color: #61acc5 !important; 
    margin:1vw auto !important;
    border: 1px solid  #30435f !important;
    border-radius: 5px;

}

.tree--item .MuiTreeItem-label{
    font-size: 1rem !important;
}
.filters .css-1wyjio-MuiSlider-root{
    color:#EAE7DC !important;
}
.tree--item svg {
    font-size: 26px !important;
    color:#EAE7DC !important;
}