.drop--aria {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    text-align: center !important;
    margin: 2vw auto !important;
    max-width: 50vw;
    min-height: 30vh;
    border: 2px dashed #939393;
    border-radius: 15px !important;
}

.sc-ikJyIC{
    padding: 4rem;
}