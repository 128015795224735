
.policy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.policy-container h1 {
  margin-bottom: 20px;
  color: #333;
}

.policy-container h2 {
  margin-bottom: 15px;
  color: #333;
}

.policy-container h3 {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #333;
}

.policy-container p {
  margin-bottom: 40px;
  color: rgb(62, 67, 65);
  font-size: medium;
  text-align: justify;
}

.policy-container a {
  color: #007bff;
  text-decoration: underline;
}

.policy-container a:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .policy-container {
    padding: 1em 4em 1em 4em;
  }
}

@media (max-width: 768px) {
  .policy-container {
    padding: 1em 2em 1em 2em;
  }
}
