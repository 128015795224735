
html {
    font-size: 90% !important;
    height:100%;
}

*,
*::before,
*::after {
    box-sizing: border-box !important;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    background-color: var(--color-white) !important;
    height: 100%;
}

.center {
    text-align: center;
}

.filters {
    line-height: 1.2 !important;
    color: #021037 !important;
    margin-left: 5px !important;
    margin-right: 10px !important;

}
#root{
    height: inherit;
}
.periodic--table {
    
    display: grid;
    grid-template-columns: repeat(17, 4.1vw) 1fr ;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    height: auto;
    margin: 0 auto;
    padding: 20px ;
}

.button-category {
    border: 0 ;
    width: 7.5vw ;
    height: 2.5vw ;
    font-size: 0.7vw ;
    text-transform: capitalize ;
    border-radius:  0 ;
    border: 1px solid #888686 ;
}

.material--table{
    margin: 2vw auto !important
}
.button-group-logic {
}


.element {
    cursor: pointer !important;
    width: 4vw ;
    height: 4vw ;
    border: 1px solid rgb(196, 192, 192);
    box-shadow: 3px 5px 4px #888888;
}

.number {
    float: left ;
    font-size: 0.9vw ;
    font-weight: bold ;
    position: absolute ;
    margin-top: -0.2vw ;
    margin-left: 0.2vw ;
}


.element-icon {
    float: right !important;
    font-size: 0.8vw !important;
    font-weight: bold !important;
    margin-top: 0.2 !important;
    color:#333 !important;
}

.symbol {
    font-weight: bold ;
    text-align: center ;
    margin-top: 0.5vw ;
    margin-top:  0.6vw ;
    font-size: 1.5vw ;
    color:#333 ;
}

.element-name {
    font-size: 0.65vw ;
    text-align: center ;
    margin-top:  auto ;
    margin-left: -0.36vw ;
    color:#333 ;
}

.bottom--box {
    grid-column: 3/13 !important;
    grid-row: 1/4 !important;
    padding: 0.9vw !important;
    font-size: 1vw !important;
    max-height: 6vw !important;
    size: inherit !important;
}
.button-group-box {
    display: grid ;
    grid-template-columns: repeat(7,5.9vw) ;
    font-size: 0.8vw ;

}
.numbers-gride {
    grid-column: 3/13 !important;
    grid-row: 1/4 !important;
    padding: 0.9vw !important;
    font-size: 1vw !important;
    max-height: 6vw !important;
    size: inherit !important;
}
.numbers-gride-box {
    display: grid !important;
    grid-template-columns: repeat(10,4vw) !important;
    font-size: 0.8vw !important;

}
.cat_name {
    border-radius: 3px !important;
    padding: 0 5px !important;
    margin-left: 5px !important;
    top: -0.7vw !important;
    position: relative !important;
}

.element-2 {
    grid-column-start: -2 !important;
}
.element-5,
.element-13 {
    grid-column-start: 13 !important;
}
.element-57,
.element-89 {
    grid-column-start: 3 !important;
}

.element-119 {
    grid-column-start: -2 !important;
}

.element-120 {
    background: #808080 !important;
    color: #464144 !important;
    cursor:default !important;
}

.element-120 .symbol {
    font-size: 0.7vw !important;
    padding-bottom: 0.27vw !important;
}
.element-120 .element-name {
    font-size: 0.6vw !important;
}

.element-121 .symbol {
    font-size: 0.7vw !important;
    padding-bottom: 0.25vw !important;
}

.element-121 .element-name {
    font-size: 0.6vw !important;
}

.element-121 {
    background: #808080 !important;
    color: #464144 !important;
    cursor:default !important;
}

.noble {
    background: #ffffff !important;
    color: #6767ba !important;
}

.alkali {
    background: #ffffff !important;
    color: #44b981 !important;
}

.alkaline {
    background: #ffffff !important;
    color: #98ad2c !important;
}

.transition {
    background: #ffffff !important;
    color: #c09300 !important;
}

.polyatomic {
    background: #ffffff !important;
    color: #148088 !important;
}

.diatomic {
    background: #ffffff !important;
    color: #39a576 !important;
}

.post-transition {
    background: #ffffff !important;
    color: #0960e4 !important;
}

.metalloid {
    background: #ffffff !important;
    color: #d33636 !important;
}

.lanthanide {
    background: #ffffff !important;
    color: #d3c100 !important;
    margin-top: 2.5vw !important;
}

.lanthanide-btn {
    background: #ffffff !important;
    color: #d3c100 !important;
}

.actinide {
    background: #ffffff !important;
    color: #e09d00 !important;
}

.unknown {
    background: #ffffff !important;
    color: rgb(13, 173, 173) !important;
}

.active {
    background: rgb(134, 183, 192) !important;
    border: 2px solid rgb(112, 112, 112);
    box-shadow: 5px 7px 6px #888888 !important;


}

.actinide-c.active {
    background: #808080 !important;
    border: 1px solid rgb(196, 192, 192) !important;
    box-shadow: 3px 5px 4px #888888 !important;
}

.lanthanide-c.active {
    background: #808080 !important;
    border: 1px solid rgb(196, 192, 192) !important;
    box-shadow: 3px 5px 4px #888888 !important;
}

.footer {
    padding: 1rem !important;
    display: flex !important;
    justify-content: center !important;
    gap: 1rem !important;
}
.ele-59,
.ele-110 {
    font-size: 0.6vw !important;
}

.element-97,.element-98,.element-99,
.element-100,.element-101,.element-102,
.element-103,.element-104,.element-104,
.element-105,.element-106,.element-107,
.element-108,.element-109,.element-110,
.element-111,.element-112,.element-113,
.element-114,.element-115,.element-116,
.element-117,.element-118,.element-119 {
    background-color: #888888 !important;
    cursor:default !important;
}
.element-97,.element-98,.element-99,
.element-100,.element-101,.element-102,
.element-103,.element-104,.element-104,
.element-105,.element-106,.element-107,
.element-108,.element-109,.element-110,
.element-111,.element-112,.element-113,
.element-114,.element-115,.element-116,
.element-117,.element-118,.element-119 .number{
    color: #2e2e2e !important;
}

.element-97,.element-98,.element-99,
.element-100,.element-101,.element-102,
.element-103,.element-104,.element-104,
.element-105,.element-106,.element-107,
.element-108,.element-109,.element-110,
.element-111,.element-112,.element-113,
.element-114,.element-115,.element-116,
.element-117,.element-118, .element-119 .active{
    background: #808080 !important;
    border: 1px solid rgb(196, 192, 192) !important;
    box-shadow: 3px 5px 4px #888888 !important;
}

.search--base{
    background-color: #ffffff !important;
    border: 2px solid #808080 !important;
    border-radius: 15px !important;
}
.carbon-group{ background-color: #a8521f};



@media screen and (max-width: 900px) {
    
}
@media (max-width: 375px) {
    .wrapper {
        width: 90% !important;
    }
    .element {
        border: 0 !important;
        width: 5vw !important;
        height: 5vw !important;
    }
    .button {
        border: 0 !important;
        width: 4vw !important;
        height: 2vw !important;
        font-size: 2vw !important;
    }
    
  
}