.result-page{
    height: 90%;
    top: 0;
}
.full-height {
    display: flex;
    min-height: calc(100vh - 240px);
    align-items: center;
    justify-content: center;

  }

.result{
    display: grid;
    justify-items: center;
    margin-top: 1vw !important;
}

#mtp--canvas{
    display:flex !important;
    justify-content: center !important;
    justify-self: center !important;
    justify-items: center !important;
    position:  relative !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 350px !important;
    min-width: 350px !important;
    border: none;
}
#mtp > canvas{
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
}
.mtp--buttons{
justify-content: center !important;
margin-top: 1vw !important;
}

.reset--button{
    display: flex;
    position: absolute;
    z-index: 2;
    padding-top: 1vw;
    padding-left: 1vw;
}

.result--card{
    margin: 2vw;
}

.css-1g86id8-MuiTreeItem-content .MuiTreeItem-iconContainer {
    align-items: center;
}
.results-tree--item .css-1g86id8-MuiTreeItem-content .MuiTreeItem-label {
    font-size: 1.5rem !important;
    color: rgb(15, 15, 15) !important;
}
.results-tree--item svg {
    font-size: 27px !important;
}
:root { --mtpsize: 40vw; }
.css-1ex1afd-MuiTableCell-root {
    font-weight: 500 !important;
    font-size: 1rem !important;
}
@media (max-width: 1200px) {
    :root { --mtpsize: 50vw; }
    .reset--button{
        padding-left: 0vw !important;
    }
}