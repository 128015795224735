.zahed-page {
  font-family: Arial, sans-serif;
  margin: 40px;
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 1920px;
}

.zahed-page .left {
  width: 360px;
  display: flex;
  flex-direction: column;
}

.zahed-page .leftInner {
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.zahed-page .right {
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px; 
}

.zahed-page .picture {
  width: 280px;
  height: auto;
  margin-bottom: 30px;
}

.zahed-page button {
  background-color: #0a1930;
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  margin-bottom: 30px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 280px;
}

.zahed-page button:hover {
  background-color: #0a1930;
  color: #ffa500;
}

.zahed-page h3,
.zahed-page h4,
.zahed-page h5 {
  margin-bottom: 10px;
}

.zahed-page p {
  margin-bottom: 10px;
  line-height: 1.5;
  color: #575757;
}

.zahed-page ul {
  margin-bottom: 20px;
}

.zahed-page li {
  margin-top: 7px;
  margin-left: 10px;
}

.zahed-page a {
  color: rgb(16, 16, 195) !important;
  text-decoration: underline;
}

.zahed-page a:hover {
  text-decoration: underline;
  color: brown !important;
}

@media screen and (max-width: 899px) {
  .zahed-page {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin: 40px 15px;
  }
  .zahed-page .left,
  .zahed-page .right {
    width: 80%;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .zahed-page .left,
  .zahed-page .right {
    width: 90%;
    margin: 0;
    padding: 0;
  }
}
