html {
    font-size: 90% !important;
}

*,
*::before,
*::after {
    box-sizing: border-box !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}


:root {
    --spacer: 1rem;
    --clr-neutral-900: #171717;
    --clr-neutral-700: #333;
    --clr-neutral-300: #cfcfcf;
    --clr-neutral-100: #fff;
    --clr-accent-400: #59e89c;
    --clr-accent-500: #00986a;

    --ff-primary: "PT Sans", sans-serif;
    --font-family: "Poppins", sans-serif;
    --dark-blue: #0a1930;
    --light-orange: #FFCD57;
    --light-blue: #1f93ff;

    --color-white: #fff;
    --dark-text: #333;
    --form-button: #2da5ff;
    --color-grey: #eae6eb;
    --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --fs-700: 2.25rem;
    --fs-600: 1.5rem;
    --fs-500: 1.25rem;
    --fs-400: 1.125rem;
    --fs-300: 0.9375rem;

    --fw-700: 700;
    --fw-400: 400;

  --box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.15);
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  line-height: 1.2;
}
h1,
h2,
h3,
span,
p {
  margin: 0;
  color:rgb(15, 15, 15)
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
.home,
.about,
.services,
.plans,
.work,
.contact {
	height: 110vh;
}

.home {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
    background-color: var(--clr-neutral-700) !important;
    opacity:1;
}
.hero {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
    background-color: var(--clr-neutral-700) !important;
  --gap: 3rem;
}
.demo-wrap {
  overflow: hidden;
  position: relative;
}
.demo-bg1 {
  opacity: 0.03;
  position: absolute;
  left: 0;
  top: 0;
  width: 60%;
  height: auto;
}
.homePage--baner{
    display: block !important;
    justify-content: center !important;
    margin: 1vw auto !important;
}
.homePage--title{
    max-width: 400px;
    
}
.h1b {font-size: 5rem !important;}
.css-m68r0y-MuiPaper-root {
    padding: 0px !important;
    color: #262B40 !important;
}
.database_records{
    font-family: "Nunito Sans", sans-serif;
    color:#262B40;
    font-weight: 700;
}
.homePage--baner> div{
    display:flex !important;
    justify-content: space-evenly !important;
    margin-right: -7vw !important;
    

}
.homePage--con {
    display: flex !important;
    padding: 4vw !important;
    border-radius: 15px !important;
    background-color: rgb(153, 151, 151, .5);
    padding: 4vw 200px !important;
    padding: 7 !important;

}
.homePage--box{
    margin: auto 3vw !important;
    margin-left: -7vw !important;
    
}
.abstract{
    background-color: rgb(235, 235, 235);
    width: 107%;
    padding-bottom: 10px;
    border: 1px solid #ffffff !important;
    border-radius: 5px !important;

}
.carousell{
    padding: 20px !important;
    background-color: #fff;
}
.center{
    margin: 40px auto !important;
    justify-content: center !important;
    margin-bottom: 20px;
}
.header{
    display: block !important;
    background-color: #25274d !important;
}

.box {
    margin: 5vw 1vw 1vw 2vw !important;
}

.news--header h1{
    font-size: 2rem !important;    
}

.box2 {
    margin: 5vw 7vw 1vw 7vw !important;
    background-color:var(--color-white) !important;
    padding: 5vw;
    box-shadow: 0px 0px 6px 0px grey;
}

.before ::before {
    content: "";
  width: 100%;
  height: 5px;
  background: var(--color-white);
}
.sponsers{
    display: flex;
    margin: 0 auto !important;
    max-width: 400px;
    justify-content: center !important;

}
.sponsers > *+* {
    margin-right: 40px;
    background-color: #fff;
}
.sponser_img{
    margin-top: 10px;
    margin-right: 40px;
    max-width: 25vw;
    border: 0.01px solid;
    border-radius: 20px !important;
    box-shadow: 0px 0px 3px 0px grey;
}
.b--one {
    background: linear-gradient(to bottom right, #0f0835, #124c5a);
}
.c--one {
    color: #25274d !important;
}

.b--two {
    background-color: #a1a1a1 !important;
}
.c--two {
    color: #25274d !important;
}

.b--three {
    background-color:  #0f0835 !important;
}
.c--three {
    display: block !important;
    color: #f3f3fd !important;
    font-size: 1.4rem !important;
    margin-top: 0.5vw !important;
    margin-left: 1vw !important;
    font-weight: var(--fw-700);
}
.b--four {
    background-color: #25274d !important;
}
.c--four {
    color: #25274d !important;
}

.homePage--logo{
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 8px;
    height: 70px !important;
}
.dev-logo {
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important;
}
.homePage--root-boxes{
    margin-top: 2vw !important;
}
.dev-logo .logo {
    height: 4vw !important;
    width: auto !important;
}

.data-base-logo {
    display: flex !important;
    height: auto !important;
    width: 97% !important;
    align-content: stretch;
}
.data-base-logo .logo {
    height: 105px !important;
    width: auto !important;
    margin: 2vw 4vw !important;
    background-color: unset;
    padding: unset;
    border: unset;
}
.data-base-logo .p {
    display: flex !important;
    justify-content:center !important;
    font-size: 6vw !important;
    font-weight: 700 !important;
    color: rgb(255, 255, 255) !important;
    margin-left: -0.4vw !important;
}
.data-base-logo .p2 {
    display: flex !important;
    position:absolute !important;
    margin-top: 6.7vw !important;
    margin-left: 9vw !important;
    font-size: 2vw !important;
    font-weight: 600 !important;
    color: rgb(255, 255, 255) !important;
}
.baner {
    padding-bottom: 6px !important;
}
.header-logo {
    height: auto !important;
    width: 60%  !important;
}
.header-nav {
    margin-top: 2vw !important;
    border-bottom: 2px solid #25274d !important;
}
.hedar_box1{
    display: flex;
    justify-content: flex-end;
    color: black !important;
}

.apps-mobile {
    display: none;
}
.apps {
    display: unset;
}

.tooltiptext {
    visibility: hidden;
    width: 85px;
    background-color: #4a4848;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -19px;
    left: -84%;
}
.header_li {
    margin-right: -10px !important;
}
.header-icon {
    height: 41px !important;
    background-color: #EAE7DC ;
    color: #D9B08C !important;
    width: auto !important;
    border: 1px solid rgb(137, 143, 136) !important;
    border-radius: 50px !important;
    margin-bottom: 10px;
    
}
.link-title-database {
    background-color: #D1D1D1 !important;
    border: 2px solid #C7C7C7 !important;
    border-radius: 15px !important;
    margin-bottom: 1vw !important;
    color: black !important;

}
.link-title-database .h1 {
    color: black !important;
}
.link-title-database .h2 {
    color: black !important;
}
.link-title-seminar {
    background-color: #B1CBE9 !important;
    border: 2px solid  #A2C1E4 !important;
    border-radius: 15px !important;
    margin-bottom: 1vw !important;
}

.database-box {
    border: 2px solid #262B40 !important;
    border-radius: 15px !important;
    padding-top: 10px !important;

}

.footer {
    justify-content: center !important;
    text-align: center !important;
}
.seminars {
    justify-content: center !important;
    text-align: center !important;
    margin-bottom: 50vw !important;
    margin-top: 10vw !important;
}
.button-group {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    cursor: pointer ;
    width: 5.5vw ;
    height: 3vw ;
    border: 2px solid rgb(196, 192, 192);
    margin: 1px 3px 2px 1px ;
    transition: .2s ease;
    border-radius: 5px;
}
.button-group-name {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    text-align:center !important;
    margin: 0 auto !important;
    font-weight: bold !important;
    color: #fff
}


.table table {
    font-family: arial, sans-serif;
    width: 100%;
    background-color: #ddd;
}

.table td{
    border: 1px solid rgb(73, 73, 73) !important;
    text-align: left;
    padding: 8px;
}


svg {
    color:#4644bb !important;
}
.abstract{
    display: inline-flex !important;
}
.abstract--link{
    margin: auto 3vw;
}


.css-1wyjio-MuiSlider-root{
    width: 90% !important;
}
.tree--name{ font-size: large !important;}


.metal{ background-color: #939393;}
.alkali-metal{ background-color: #fa0000;}
.alkali-earth-metal{ background-color: #db6b2b;}
.transition-metal{ background-color: #176c72;}
.boron-group{ background-color: #57d41d;}
.carbon-group{ background-color: #a8521f;}
.lanthoid{ background-color: #bb28ff;}
.actinoid{ background-color: #4e39a8;}
.metalloid{ background-color: #d33636;}
.non-metal{ background-color: #6208f3;}
.noble-gas{ background-color: #61b1df;}
.chalcogens{ background-color: #ad8f2c;}
.halogens{ 
    background-color: #543b6b !important;
}
.pnictogens{background-color: #014f7c;}
.other-metal{background-color: #2b6b00;}
.logical{
    background-color: #333332;
    margin-top: 1vw !important;
}
.non{
    border-color: #ffffff;
    margin-top: 1vw !important;
}
.search--base{
    background-color: #ffffff !important;
    border: 2px solid #808080 !important;
}
.drop--aria {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    margin: 2vw auto !important;
    max-width: 500px;
    border: 2px dashed #939393;
    border-radius: 15px !important;
}



.css-19phbuo-MuiPaper-root{
    width: 91% !important;
}
.css-oifjk1-MuiTableContainer-root {
    width: 95% !important;
}
.icon {
    width: 60px;
    margin: 0 auto;
    display: block !important;
}


@media screen and (max-width: 900px) {
   
    .apps-mobile {
        display: unset;
    }
    .apps {
        display: none;
    }
.hedar_box1{
    display:grid ;
    grid-auto-flow: row;
    width: 100%;
    justify-content:unset !important;
    
}

.banner{
    padding-bottom: 0vw !important;
}
.css-xcqbav-MuiGrid-root{
    display: flex;
    justify-content: flex-start;
    padding-left: 30px !important;
    margin-bottom: -6px !important;
    padding-bottom: 16px !important;
    background-color: black;
    margin-top: 30px;
}
.css-1gcqb8n-MuiGrid-root{
    display: flex !important;
    max-width: 100% !important;
    justify-content:left !important;
}
.hedar_box2 {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px !important;
    padding-top: 0px !important;
    margin-left: 0px !important;
    color: black !important;
    padding-bottom: 14px;
    margin-bottom: -13px;
    flex-wrap: wrap;
}
.data-base-Account {
    height: 40px !important;
    width: 40px !important;
    margin-right: 0px !important;
    margin: 0 auto;
    min-width: 30px !important;
    display: flex;
    min-height: 30px !important;
    margin-bottom: -10px !important;

    }
    .radio-group2 div {
        display:block !important;
    }
    .radio--group3{
        display:unset !important;
    }
    .css-14gnykw {
        margin: 0px 0px 0px 0px !important;
    }
    .css-mnn31 {
        padding: 4px 9px 5px !important;
    }

    .periodic--table {
        grid-template-columns: repeat(17,5.15vw) 1fr !important;
        grid-column-gap: 1px !important;
        grid-row-gap: 1px !important;
        padding: 1vw !important;
    }

    .element {
        width: 5vw !important;
        height: 5vw !important;
    }
    .number {
        font-size: 1.1vw !important;
    }
    .symbol {
        font-size: 1.9vw !important;
    }
    .button-group-box {
        grid-template-columns: repeat(7,7.4vw) !important;
        font-size: 1vw !important;
    }
    .button-group {
        width: 7vw !important;
        height: 3.8vw !important;
    }
}
@media (max-width: 375px) {
    .periodic---table {
    grid-template-columns: repeat(17, 5.35vw) 1fr !important;
    padding-left: 5px !important;
}
    .header-logo {
        height: auto !important;
        width: auto  !important;
        margin-left: 0vw !important;
    }
    .data-base-logo {
        margin-bottom: -7vw !important;
    }
    
    .apps-mobile {
        display: unset;
    }
    .apps {
        display: none;
    }
    .header-icon {
        height: 7vw !important;
        width: 7vw !important;;
    }
    .header_li{
        width: 20px !important;
    }
    .data-base-Account {
        height: 20px !important;
        width: 20px !important;
        margin-right: 0px !important;
        margin: 0 auto;
        min-width: 20px !important;
        margin-left: 3px !important;
    }
    .radio-group {
    font-size: 2.5vw !important;
    }
    .radio-group div span{
    margin-right: 0vw !important;
}
    .radio {
        margin-left: -2vw !important;
    }
    .search {
    height: auto !important;
    margin-top: 10px !important;
    }
    .btn {
        height: 7vw !important;
        margin-right: 20px !important;
        
    }
    .btn p {
        margin-top: 0px !important;
    }
    .c--three{
        font-size: 0.9rem !important;
    }
    .baner {
    padding-bottom: 0px !important;
    margin-bottom: 13px !important;
}   
    .css-xcqbav-MuiGrid-root{
        padding-bottom: 0 !important;
    }
    .header{
        margin-bottom: -5px !important;

     }

    .hedar_box2{
    display: flex;
    justify-content: flex-start;
    margin-top: 0px !important;
    padding-top: 3px !important;
    padding-bottom: 5px;
    color: black !important;
}
    .data-base-logo .logo {
    height: 90px !important;
    margin-top: 21px;
    margin-left: 26px;
    margin-bottom: 33px;
}
.css-xcqbav-MuiGrid-root {
    display: flex;
    justify-content: flex-start;
    margin-bottom: -6px !important;
    padding-bottom: 16px !important;
    background-color: black;
    margin-top: 60px;
    padding-left: 19px !important;
}
}
