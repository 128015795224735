.containerStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 800px;
    min-width: 400px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.94);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    gap: 20px;
  }

.names{
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 40px;
}
  
  @media (max-width: 1100px) {
    .containerStyle {
        flex-direction: column;
    }
    .names{
        font-size: 28px;
    }
  }
  