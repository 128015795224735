.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  min-width: 400px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.94);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.avatar-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}


.avatar-container .specificbutton {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  font-weight: bold;
  font-size: 16px;
  &:hover {
    background-color: #f3fbff;
    box-shadow: 0 0 0 1px #39f;
  }
}

.avatar-container .specificbutton:disabled {
  background-color: #ddd;
  color: #999;
  cursor: not-allowed;
  box-shadow: none;
}

.avatar-container .success-message {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  color: green;
}

.avatar-container .error-message {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  color: red;
}

/* .img-preview {
  overflow: hidden;
  margin-top: 20px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
} */

.avatar-container h2 {
  margin-bottom: 10px;
}

.avatar-container .custom-select {
  padding: 6px;
  border: 0.09rem solid #383838;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.94);
}

.avatar-container div {
  margin-bottom: 10px;
}

.avatar-container label {
  display: block;
  margin-bottom: 2px;
  font-weight: bold;
}

.avatar-container input[type="text"], 
.avatar-container input[type="date"], 
.avatar-container input[type="email"], 
.avatar-container input[type="url"] {
  width: 100%;
  padding: 5px;
  border: 0.09rem solid #383838;
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  &:focus {
    outline: none;
    border: 0.12rem solid #1976d2;
  }
}


.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}


@media screen and (max-width: 700px) {
  .avatar-buttons {
    flex-direction: column;
  }
  .avatar-container .specificbutton {
width: 100%;
  }
}
